:root {
    --black: #212529;
    --black-5: #F7F7F7;
    --black-15: #F1F2F3;
    --black-20: #E0E3E6;
    --black-40: #BEC2C7;
    --black-60: #757D85;

    --text-2: #505565;
    --text-3: #8B90A0;

    --blue-5:#F2F8FF;
    --blue:#0075FF;

    --dark-80: #717893;

    --accent-red: #CE4040;
    --accent-red-hover: #a63434;
    --accent-red-5: #FDF4F4;
    --accent-yellow: #FBB605;
    --accent-yellow-5: #FFF5E2;
    --accent-green: #3ABF7C;
    --accent-green-5: #F1FCF6;
    --accent-violet: #5928E5;
    --accent-violet-5: #F6F2FF;
}
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
body, #root, #root {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
.backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5)
}
.spinner {
    height: 40px;
    width: 40px;
    border: 2px solid #fff;
    border-top: 2px solid #0075FF;
    border-radius: 100%;
    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
    z-index: 1000;
}
.side-bar {
    transition: .5s;
    width: 65px;

}
.side-bar > .menu {
    overflow-y: scroll;
    height: calc(100% - 100px);
}
.side-bar:hover, .side-bar.opened {
    transition: .5s;
    width: 300px;
}
.fix-wrapper {
    transition: .5s;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fbfbfb;
}
.fix-icon {
    margin: 10px;
}
@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}
