.content {
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 20px;
    overflow: hidden;
}
.content-mobile {
    padding: 0 !important;
}
.body {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.mobile {
    padding: 0;
}
.button {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    box-shadow: 4px 8px 24px rgba(33, 96, 170, 0.15);
    border-radius: 12px !important;
    padding: 0 36px;
    width: 100%;
}
.button-mobile {
    height: 48px !important;
}
.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}
.info {
    background: #FFFFFF;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
    padding: 14px;
    border-radius: 20px;
}
.items {
    right: -15px !important;
    left: -15px !important;
    bottom: 130% !important;
}
.bottom {
    max-width: 350px;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: auto;
}
.suggestion-item {
    padding: 8px;
    margin-bottom: 6px;
    min-height: unset;
    cursor: pointer;
}
.title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: var(--black);
    margin-bottom: 12px;
}
.input {
    margin-bottom: 12px;
}
.info-mobile {
    left: 0;
    right: 0;
    bottom: 0;
    max-width: unset;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.closeButton {
    position: absolute;
    right: 40px;
    top: 40px;
    background-color: var(--black);
    opacity: .9;
}
.closeButton > svg > path {
    stroke: #c1c1c1;
}
.closeButtonMobile {
    right: 15px !important;
}
