.container {
    position: relative;
}
.items {
    max-height: 252px;
    overflow: auto;
    position: absolute;
    right: 0;
    left: 0;
    z-index: 200;
    box-shadow: 2px 8px 26px #00000014;
    background-color: #ffffff !important;
    flex-direction: column;
    border-radius: 20px;
    padding-top: 6px;
    padding-bottom: 6px;
}
.item {
    margin: 0;
    padding: 6px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: var(--black);
    cursor: pointer;
}
.icon {
    z-index: 999;
    cursor: pointer;
}
.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}
.body {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.input {
    padding-right: 60px !important;
}
.info.mobile {
    left: 0;
    right: 0;
    bottom: 0;
    max-width: unset;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}